import axios from 'axios';
import { getAccessToken } from 'extensions/userInfoExtension';


export const getConversation = async (id) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/prod/conversations/${id}`,
        headers: {
            'accept': '*/*',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${getAccessToken()}`
        }
    };

    return await axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
}

export const getConversations = async () => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: '/prod/conversations',
        headers: {
            'accept': '*/*',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${getAccessToken()}`
        }
    };

    return await axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
}

export const addConversation = async (text) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/prod/conversations',
        headers: {
            'accept': '*/*',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${getAccessToken()}`
        },
        data: {
            "Text": text
        }
    }

    return await axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
}

export const deleteConversation = async (id) => {
    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `/prod/conversations/${id}`,
        headers: {
            'accept': '*/*',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${getAccessToken()}`
        }
    };

    return await axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
}

export const getJuvolySessionId = async (v2=false) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/prod/juv-session${v2 ? '?v2=true' : ''}`,
        headers: {
            'accept': '*/*',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${getAccessToken()}`
        }
    }

    return await axios.request(config)
        .then((response) => {
            var response = JSON.parse(response.data);
            return response;
        })
        .catch((error) => {
            console.error(error);
            return null;
        });
}