import jsPDF from "jspdf";
import userLogos from './userLogos.json';
import wesselLogo from '../assets/img/wessel.png';
import LRWLogo from '../assets/img/LRW.png';


export class LawDiskPDFCreator {
    create(data, defaultLogoPath, UserId, callback) {
        const { summary, transcript, date, tags, isSummaryOnly } = data;
        const doc = new jsPDF();
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;
        let yOffset = 20; // Startpositie Y
        const marginLeft = 20;

        let logoImage = defaultLogoPath;
        if (userLogos[UserId]) {
          if (userLogos[UserId] === 'wesselLogo') {
            logoImage = wesselLogo;
          }
            if (userLogos[UserId] === 'LRWLogo') {
            logoImage = LRWLogo;
        }
    }
        // Laad het logo dynamisch
        const image = new Image();
        image.src = logoImage;

        // Wacht tot de afbeelding is geladen en voeg deze toe aan het PDF-document
        image.onload = () => {
            this.addLogoToPage(doc, image, pageWidth, pageHeight);

            // Titel: "LawDisk Notulen"
            doc.setFont("helvetica", "bold");
            doc.setFontSize(24);
            doc.text("LawDisk Notulen", pageWidth / 2, yOffset, { align: "center" });
            yOffset += 20;

            // Subtitel: "Van de vergadering op [datum en tijd]"
            doc.setFont("helvetica", "normal");
            doc.setFontSize(12);
            doc.text(`Van de vergadering op ${date}`, pageWidth / 2, yOffset, { align: "center" });
            yOffset += 15;

            // Tags sectie
            doc.setFont("helvetica", "italic");
            doc.setFontSize(12);
            doc.text(`Tags: ${tags.join(", ")}`, marginLeft, yOffset);
            yOffset += 15;

            // Samenvatting Sectie
            yOffset = this.addSection(doc, "Samenvatting:", summary, yOffset, pageHeight, image, pageWidth, pageHeight);

            // Voeg transcriptie toe als isSummaryOnly false is
            if (!isSummaryOnly) {
                yOffset = this.checkPageBreak(doc, yOffset, pageHeight, 40, image, pageWidth, pageHeight);
                yOffset = this.addSection(doc, "Transcriptie:", transcript, yOffset, pageHeight, image, pageWidth, pageHeight);
            }

            callback(doc);
        };
    }

    addSection(doc, title, content, yOffset, totalPageHeight, image, pageWidth, pageHeight) {
        const marginLeft = 20;

        // Add Section Title
        doc.setFont("helvetica", "bold");
        doc.setFontSize(16);
        doc.text(title, marginLeft, yOffset);
        yOffset += 10;

        // Parse Markdown Content
        yOffset = this.parseMarkdown(doc, content, yOffset, totalPageHeight, image, pageWidth, pageHeight);

        return yOffset;
    }

    parseMarkdown(doc, markdown, yOffset, pageHeight, image, pageWidth) {
        const lines = markdown.split("\n");
        const marginLeft = 20;

        lines.forEach((line) => {
            if (line.startsWith("#### ")) {
                // H2 Style
                doc.setFont("helvetica", "bold");
                doc.setFontSize(16);
                line = line.replace("#### ", "");
            } else {
                // Normal paragraph style
                doc.setFont("helvetica", "normal");
                doc.setFontSize(12);
            }

            // Check for page break
            if (yOffset > pageHeight - 20) {
                doc.addPage();
                yOffset = 20;
                this.addLogoToPage(doc, image, pageWidth, pageHeight);
            }

            const wrappedLines = doc.splitTextToSize(line, 170);
            wrappedLines.forEach((wrappedLine) => {
                doc.text(wrappedLine, marginLeft, yOffset);
                yOffset += 7;
            });
        });

        return yOffset;
    }

    checkPageBreak(doc, yOffset, totalPageHeight, requiredSpace, image, pageWidth, pageHeight) {
        if (yOffset + requiredSpace > totalPageHeight) {
            doc.addPage();
            yOffset = 20;
            this.addLogoToPage(doc, image, pageWidth, pageHeight);
        }
        return yOffset;
    }

    addLogoToPage(doc, image, pageWidth, pageHeight) {
        const desiredWidth = 42; 
        const originalWidth = image.width;
        const originalHeight = image.height;
    
        const aspectRatio = originalHeight / originalWidth;
        const logoWidth = desiredWidth;
        const logoHeight = logoWidth * aspectRatio;
    
        const xPosition = pageWidth - logoWidth - 10; 
        const yPosition = pageHeight - logoHeight - 10; 
    
        doc.addImage(image, "PNG", xPosition, yPosition, logoWidth, logoHeight);
    }
    
    savePDF(doc, formattedDate) {
        const documentName = `LawDisk_Notulen_${formattedDate}.pdf`;
        doc.save(documentName);
    }
}
