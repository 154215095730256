import {
  form,
  FormLabel,
  Input,
  InputGroup,
  List,
  ListItem,
  ListIcon,
  Collapse,
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import React, { useState, useEffect } from "react";

function EmailInput({ onEmailChange }) {
  // State om email in op te slaan en een boolean om de lijst met criteria te laten zien
  const [email, setEmail] = useState("");
  const [isListVisible, setIsListVisible] = useState(false);

  // Functie op de email in de state te zetten
  const handleEmailChange = (e) => {
    const lowerCaseEmail = e.target.value.toLowerCase();
    setEmail(lowerCaseEmail);
    setIsListVisible(lowerCaseEmail.trim() !== "");
  };

  // criteria waar de email aan moet voldoen
  const criteria = {
    hasAtSymbol: /@/.test(email),
    hasDomain: /\.[a-z]{2,}$/i.test(email),
    hasNoSpaces: /\s/.test(email) === false,
    startsWithValidChar: /^[^@]+/.test(email),
    validStructure: /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email),
  };

  // Hier ga je alle criteria van de email na
  const isEmailValid = Object.values(criteria).every((value) => value);

  // Geef de email mee aan het parent component
  useEffect(() => {
    if (onEmailChange) {
      onEmailChange(email, isEmailValid);
    }
  }, [email, isEmailValid, onEmailChange]);

  return (
    <form>
      <InputGroup>
        <Input
          borderRadius="15px"
          mb="10px"
          fontSize="sm"
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Uw e-mailadres"
          size="lg"
        />
      </InputGroup>
      <FormLabel ms="4px" fontSize="xs" fontWeight="normal">
        <Collapse in={isListVisible && !isEmailValid} animateOpacity>
          <List spacing={3}>
            <ListItem>
              <ListIcon as={WarningIcon} color="red.500" />
              Geef een geldig e-mailadres op
            </ListItem>
          </List>
        </Collapse>
      </FormLabel>
    </form>
  );
}

export default EmailInput;
