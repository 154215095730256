export const changelogData = [
  {
    version: "0.3.0",
    date: "2024-12-06",
    changes: [
      { text: "Live transcriptie meekijken." },
      { text: "Verbeterde Word/PDF export." },
      { text: "Account aanmaken (incl. Google inlog) en wachtwoord vergeten functionaliteit." },
      { text: "Custom branding.", type:"Experimenteel" },
      { text: "Meertalig model en v2 model.", type:"Experimenteel" },
      { text: "Diverse UI verbeteringen." },
      { text: "Automatisch gegenereerde titels." },
      { text: "Verbeterde opmaak notulen." },




    ],
  },
    {
      version: "0.2.0",
      date: "2024-11-15",
      changes: [
        { text: "Bugfixes" },
        { text: "Mobiele versie verbeterd inclusief lockscreen" },
        { text: "Eigen opnames uploaden", type:"Experimenteel" },
      ],
    },
    {
      version: "0.1.1",
      date: "2024-10-30",
      changes: [
        { text: "Mogelijkheid om notities te exporteren als PDF en Word bestand.", type: "Experimenteel" },
      ],
    },
    {
      version: "0.1.0",
      date: "2024-10-01",
      changes: [
        { text: "Lancering van LawDisk" },
        { text: "Tagging-systeem toegevoegd" },
        { text: "Beveiligingsfuncties geïmplementeerd" },
        { text: "Samenvattingsfunctie versie 1", type: "Experimenteel" },

      ],
    },
  ];
  