// Chakra Imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Switch,
  Text,
  useColorMode,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { HSeparator } from "components/Separator/Separator";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import SettingsContext from "contexts/SettingsContext";
import RecordingContext from "contexts/RecordingContext";

export default function Configurator(props) {
  const { secondary, isOpen, onClose, fixed, ...rest } = props;

  const settingsContext = useContext(SettingsContext);
  const recordingContext = useContext(RecordingContext);
  // console.log(settingsContext)

  const {
    liveTranscriptionEnabled = false,
    setLiveTranscriptionEnabled = () => {},
    waveformVisible = false,
    setWaveformVisible = () => {},
    isJuvV2 = false,
    setIsJuvV2 = () => {},
  } = settingsContext || {};

  const {isRecording = false} = recordingContext || {};
  // Inside your Configurator component

  const { colorMode, toggleColorMode } = useColorMode();

  // Chakra Color Mode
  let fixedDisplay = "flex";
  if (props.secondary) {
    fixedDisplay = "none";
  }

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const settingsRef = React.useRef();

  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}
      >
        <DrawerContent>
          <DrawerHeader pt="24px" px="24px">
            <DrawerCloseButton />
            <Text fontSize="xl" fontWeight="bold" mt="16px">
              LawDisk&#8482; | instellingen
            </Text>
            <HSeparator />
          </DrawerHeader>
          <DrawerBody w="340px" ps="24px" pe="40px">
            <Flex flexDirection="column">
              <Box
                display={fixedDisplay}
                justifyContent="space-between "
                mb="16px"
              ></Box>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="24px"
              >
                <Text fontSize="md" fontWeight="600" mb="4px">
                  Donker/Licht
                </Text>
                <IconButton
                  aria-label="Toggle color mode"
                  onClick={toggleColorMode}
                  icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                />
              </Flex>

              {settingsContext && (
                <Flex flexDirection="column">
                  <HSeparator />
                  <Box
                    display={fixedDisplay}
                    justifyContent="space-between "
                    mb="24px"
                  ></Box>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb="24px"
                  >
                    <Text fontSize="md" fontWeight="600">
                      Toon live transcriptie
                    </Text>
                    <Switch
                      isChecked={liveTranscriptionEnabled}
                      onChange={(e) =>
                        setLiveTranscriptionEnabled(e.target.checked)
                      }
                    />
                  </Flex>

                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb="24px"
                  >
                    <Text fontSize="md" fontWeight="600">
                      Toon geluidsgolven
                    </Text>
                    <Switch
                      isChecked={waveformVisible}
                      onChange={(e) => setWaveformVisible(e.target.checked)}
                    />
                  </Flex>
                  <HSeparator />
                  <Box
                    display={fixedDisplay}
                    justifyContent="space-between "
                    mb="24px"
                  ></Box>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb="24px"
                  >
                    <Text fontSize="md" fontWeight="600">
                      Gebruik LawDisk&#8482; v2 model.
                    </Text>
                    <Switch
                      isChecked={isJuvV2}
                      isDisabled={isRecording}
                      title={
                        isRecording
                          ? "Tijdens transriberen kunt u niet veranderen van model."
                          : ""
                      }
                      onChange={(e) =>
                        setIsJuvV2(e.target.checked)
                      }
                    />
                  </Flex>
                </Flex>
              )}

              <HSeparator />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};
