import React, { useState } from 'react';

const SettingsContext = React.createContext();

export const SettingsProvider = ({ children }) => {
    const [liveTranscriptionEnabled, setLiveTranscriptionEnabled] = useState(true);
    const [waveformVisible, setWaveformVisible] = useState(true);
    const [isJuvV2, setIsJuvV2] = useState(true);
  
    return (
      <SettingsContext.Provider
        value={{
          liveTranscriptionEnabled,
          setLiveTranscriptionEnabled,
          waveformVisible,
          setWaveformVisible,
          isJuvV2,
          setIsJuvV2
        }}
      >
        {children}
      </SettingsContext.Provider>
    );
  };
  
export default SettingsContext;
