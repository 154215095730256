import {
  Badge,
  Box,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Radio,
  RadioGroup,
  Input,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import Markdown from "react-markdown";
import CardBody from "components/Card/CardBody.js";
import { format } from "date-fns";
import { getUserName } from "extensions/userInfoExtension";
import { getUserId } from "extensions/userInfoExtension";
import React, { useRef, useEffect, useState } from "react";
import {
  FaFileContract,
  FaShare,
  FaFileWord,
  FaEdit,
  FaFilePdf,
  FaTrashAlt,
  FaArrowLeft,
  FaCheck,
} from "react-icons/fa";
import { BiAnalyse } from "react-icons/bi";
import { FaBook } from "react-icons/fa6";
import { saveAs } from "file-saver";
import { Packer } from "docx";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import { LawDiskDocumentCreator } from "../../../../services/LawDiskDocumentCreator";
import { LawDiskPDFCreator } from "../../../../services/LawDiskPDFCreator";
import { useHistory } from "react-router-dom"; // Voor navigatie
import lawdiskLogo from "../../../../assets/img/lawdisk.png";
import { deleteConversation } from "api/lawdiskService";

const NotulenDetailCard = ({
  id,
  summary,
  text,
  date,
  title,
  tags,
  userId,
  onDelete,
}) => {
  const toast = useToast();
  const history = useHistory(); // Instantie van useHistory voor navigatie
  const textColor = useColorModeValue("gray.700", "white");
  const summaryRef = useRef(null); // Reference to the summary section
  const [summaryHeight, setSummaryHeight] = useState(0); // State to store summary height

  useEffect(() => {
    if (summaryRef.current) {
      setSummaryHeight(summaryRef.current.offsetHeight + 200); // Get the height of the summary section
    }
  }, [summary]); // Recalculate if the summary content changes

  const textRef = useRef(null); // Reference to the text container

  useEffect(() => {
    if (textRef.current && summaryRef.current) {
      const textHeight = textRef.current.scrollHeight;
      const summaryHeight = summaryRef.current.offsetHeight;

      if (textHeight > summaryHeight) {
        setScrollable(true); // Stel scrollgedrag in
      } else {
        setScrollable(false); // Geen scrollgedrag
      }
    }
  }, [text, summary]);

  const infoid = id;
  const userFriendlyDate =
    date && !isNaN(new Date(date).getTime())
      ? format(new Date(date), "dd-MM-yyyy HH:mm:ss")
      : "onbekend";

  const formattedDate =
    date && !isNaN(new Date(date).getTime())
      ? format(new Date(date), "dd-MM")
      : "onbekend";

  const userName = getUserId() === userId ? getUserName() : userId;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [documentOption, setDocumentOption] = useState("0");
  const [conversationIdToDelete, setConversationIdToDelete] = useState(null);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editableTitle, setEditableTitle] = useState(title);

  React.useEffect(() => {
    if (summary === null) {
      // Stel een timer in voor autorefresh na 5 seconden
      const timer = setTimeout(() => {
        window.location.reload();
      }, 5000);

      return () => clearTimeout(timer); // Opruimen van de timer
    }
  }, [summary]);

  const cancelDialogRef = useRef();
  const cancelDeleteRef = useRef();

  const openWordDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const openDeleteDialog = () => setIsDeleteDialogOpen(true);
  const closeDeleteDialog = () => setIsDeleteDialogOpen(false);

  const generateWordDocument = () => {
    const isSummaryOnly = documentOption === "0";

    const documentCreator = new LawDiskDocumentCreator();
    const doc = documentCreator.create({
      summary: summary,
      transcript: isSummaryOnly ? "" : text,
      title: title,
      date: userFriendlyDate,
      tags: tags,
      isSummaryOnly: isSummaryOnly,
    });

    Packer.toBlob(doc).then((blob) => {
      const documentName = `LawDisk_Notulen_${formattedDate}.docx`;
      saveAs(blob, documentName);
      toast({
        title: "Word-document gegenereerd",
        description: "Het notulen Word-document is succesvol gegenereerd.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    });
  };

  const generatePDF = () => {
    const isSummaryOnly = documentOption === "0";
    const pdfCreator = new LawDiskPDFCreator();

    pdfCreator.create(
      {
        summary: summary,
        transcript: text,
        date: userFriendlyDate,
        tags: tags,
        isSummaryOnly: isSummaryOnly,
      },
      lawdiskLogo, // Dit is je defaultLogoPath
      userId, // Voeg hier het UserId toe
      (pdfDoc) => {
        pdfCreator.savePDF(pdfDoc, formattedDate);
        toast({
          title: "PDF gegenereerd",
          description: "Het notulen PDF-bestand is succesvol gegenereerd.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    );
  };

  const ConversationDelete = async (notulenId) => {
    try {
      // Zorg ervoor dat we alleen de id gebruiken, niet het hele object
      const idToDelete = notulenId.id || notulenId; // Haal de id uit het object indien nodig

      console.log("Deleting conversation with ID:", idToDelete); // Log de id om te controleren

      if (idToDelete !== null) {
        await deleteConversation(idToDelete); // Voer de DELETE request uit met de id
      }

      if (onDelete) {
        onDelete(); // Roep de onDelete prop aan om te handelen na het verwijderen
      }

      setConversationIdToDelete(null);
      closeDeleteDialog(); // Sluit de delete dialoog

      toast({
        title: "Verwijderd",
        description: "De notulen zijn succesvol verwijderd.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      history.push("/admin/dashboard/notulen"); // Navigeer terug naar de notulenlijst
    } catch (error) {
      console.error("Error deleting conversation:", error);
      toast({
        title: "Fout",
        description: "Er is iets misgegaan bij het verwijderen.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Back button
  const tooltipBg = useColorModeValue("gray.300", "gray.700");
  const buttonBg = useColorModeValue("gray.200", "gray.600");
  const buttonHoverBg = useColorModeValue("gray.300", "gray.500");
  const iconColor = useColorModeValue("gray.800", "white");

  return (
    <>
      <Flex position="relative" mb="20px">
        <Flex position="absolute" top="0" left="-10px" zIndex="10">
          <Tooltip
            hasArrow
            label="Ga terug naar Dashboard"
            bg={tooltipBg}
            color={useColorModeValue("black", "white")}
          >
            <IconButton
              icon={<FaArrowLeft />}
              aria-label="Terug naar Dashboard"
              color={iconColor}
              onClick={() => history.push("/admin/dashboard")}
              borderRadius="50%"
              boxSize="50px"
              bg={buttonBg}
              _hover={{ bg: buttonHoverBg }}
            />
          </Tooltip>
        </Flex>
      </Flex>

      <Card minHeight="290.5px" p="1.2rem">
        <CardBody w="100%">
          <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%">
            <Flex
              flexDirection="column"
              h="100%"
              lineHeight="1.6"
              width={{ lg: "45%" }}
              p={4}
            >
              <Flex align="center" mt={2}>
                {!isEditingTitle ? (
                  <Flex align="center">
                    <Text as="span" color="gray.500" fontWeight="bold" mr={2}>
                      {title && title !== "" ? title : "Notulen"}
                    </Text>
                    <IconButton
                      aria-label="Titel bewerken"
                      icon={<FaEdit />}
                      size="sm"
                      fontSize="20px"
                      variant="ghost"
                      onClick={() => {
                        setIsEditingTitle(true);
                        setEditableTitle(title); // Zorg dat de huidige titel wordt ingeladen
                      }}
                      color="blue.500"
                    />
                  </Flex>
                ) : (
                  <Flex align="center" gap={2}>
                    <Input
                      placeholder="Titel invoeren..."
                      variant="flushed"
                      value={editableTitle}
                      onChange={(e) => setEditableTitle(e.target.value)} // Update de lokale state
                      autoFocus
                      fontSize="sm"
                      fontWeight="bold"
                      color="gray.500"
                      _focus={{ borderColor: "blue.500" }}
                      height="1.5rem"
                      width={{ base: "100%", md: "360px" }}
                    />

                    <IconButton
                      aria-label="Opslaan"
                      icon={<FaCheck />}
                      size="sm"
                      fontSize="20px"
                      colorScheme="blue"
                      onClick={() => {
                        setIsEditingTitle(false); // Sluit de bewerkmodus
                        setTitle(editableTitle); // Update de hoofdstate
                      }}
                    />
                  </Flex>
                )}
              </Flex>

              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                Notulen gestart door:{" "}
                <Text as="span" color="gray.500" fontWeight="bold">
                  {userName}
                </Text>
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                Datum notulen:{" "}
                <Text as="span" color="gray.500" fontWeight="bold">
                  {userFriendlyDate}
                </Text>
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                ID:{" "}
                <Text as="span" color="gray.500" fontWeight="bold">
                  {id}
                </Text>
              </Text>
              <Flex>
                <HStack
                  p={4}
                  flexDirection={{ sm: "column", md: "row" }}
                  h="100%"
                  lineHeight="1.6"
                  width={{ lg: "100%" }}
                  flexWrap="wrap"
                >
                  {tags?.map((tag, index) => (
                    <Box key={index}>
                      <Badge colorScheme="green" textAlign="center">
                        {tag}
                      </Badge>
                    </Box>
                  ))}
                </HStack>
              </Flex>

              <Flex
                flexDirection="column"
                justifyContent="flex-start"
                mb="1rem"
              >
                <ButtonGroup gap="2" align="center">
                  <Tooltip hasArrow label="Delen" bg="gray.300">
                    <IconButton
                      colorScheme="purple"
                      aria-label="Delen"
                      fontSize="20px"
                      icon={<FaShare />}
                      onClick={() =>
                        toast({
                          title: "Binnenkort beschikbaar",
                          description:
                            "Het delen van de notulen is binnenkort beschikbaar.",
                          duration: 9000,
                          isClosable: true,
                        })
                      }
                    />
                  </Tooltip>
                  <Tooltip
                    hasArrow
                    label="Genereer PDF / Word document"
                    bg="gray.300"
                  >
                    <IconButton
                      colorScheme="blue"
                      aria-label="Genereer PDF / Word document"
                      fontSize="20px"
                      icon={<FaFileWord />}
                      onClick={openWordDialog}
                    />
                  </Tooltip>
                  <Tooltip hasArrow label="Genereer contract" bg="gray.300">
                    <IconButton
                      colorScheme="yellow"
                      aria-label="Genereer Contract"
                      fontSize="20px"
                      icon={<FaFileContract />}
                      onClick={() =>
                        toast({
                          title: "Binnenkort beschikbaar",
                          description:
                            "Het genereren van contracten is binnenkort beschikbaar.",
                          duration: 5000,
                          isClosable: true,
                        })
                      }
                    />
                  </Tooltip>

                  <Tooltip
                    hasArrow
                    label="Persoonlijk woordenboek"
                    bg="gray.300"
                  >
                    <IconButton
                      colorScheme="teal"
                      aria-label="Persoonlijk Woordenboek"
                      fontSize="20px"
                      icon={<FaBook />}
                      onClick={() =>
                        toast({
                          title:
                            "Persoonlijk Woordenboek binnenkort beschikbaar.",
                          description:
                            "Voeg persoonlijke woorden toe voor een verbeterde ervaring.",
                          duration: 5000,
                          isClosable: true,
                        })
                      }
                    />
                  </Tooltip>
                  <Tooltip hasArrow label="Notulen verwijderen" bg="gray.300">
                    <IconButton
                      colorScheme="red"
                      aria-label="Notulen verwijderen"
                      fontSize="20px"
                      icon={<FaTrashAlt />}
                      onClick={openDeleteDialog}
                    />
                  </Tooltip>
                </ButtonGroup>
              </Flex>

              <Box mt={4}>
                <Divider />

                <Text fontSize="sm" fontWeight="bold" textAlign="center" mt={2}>
                  SAMENVATTING
                </Text>
                <Divider />
              </Box>

              {/* Summary Content */}
              {summary === null ? (
                <Flex
                  align="center"
                  justify="center"
                  mt={4}
                  flexDirection="column"
                >
                  <Spinner size="lg" color="blue.500" mb={2} />
                  <Text fontSize="sm" color={textColor} fontWeight="bold">
                    Samenvatting wordt gegenereerd, even geduld aub...
                  </Text>
                </Flex>
              ) : (
                <Text
                  ref={summaryRef}
                  fontSize="sm"
                  color={textColor}
                  fontWeight="normal"
                >
                  <Markdown
                    components={ChakraUIRenderer()}
                    children={summary}
                    skipHtml
                  />
                </Text>
              )}
            </Flex>

            <Flex flexDirection="column" width={{ lg: "55%" }} p={4}>
            <Text
                fontSize={{ sm: "md", md: "lg", lg: "md" }}
                align="center"
                color={textColor}
                fontWeight="bold"
              >
                Volledige transcriptie
              </Text>
              <Divider />

              <Flex
                justify="center"
                borderRadius="15px"
                width="100%"
                mt={4}
                height={`${summaryHeight}px`}
                overflowY="auto"
                border="1px solid"
                borderColor={useColorModeValue("gray.200", "gray.600")}
                p="10px"
                bg={useColorModeValue("gray.100", "gray.800")}
              >
                <Box position="relative" padding="10">
                  <Text fontSize="sm" color={textColor} fontWeight="normal">
                    {text}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </CardBody>

        <AlertDialog
          isOpen={isDialogOpen}
          leastDestructiveRef={cancelDialogRef}
          onClose={closeDialog}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent borderRadius="15px" position="relative">
              <IconButton
                icon={<Text fontSize="xl">✕</Text>}
                aria-label="Sluit dialoogvenster"
                position="absolute"
                top="8px"
                right="8px"
                colorScheme="gray"
                variant="ghost"
                onClick={closeDialog}
                _hover={{ bg: "gray.100" }}
              />
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Kies documentformaat en inhoud
              </AlertDialogHeader>
              <Divider />

              <AlertDialogBody>
                <Text mt={4}>Kies het formaat voor het document:</Text>
                <Flex justify="space-around" mt="4" mb="1rem">
                  <IconButton
                    colorScheme="blue"
                    aria-label="Genereer Word Document"
                    icon={<FaFileWord />}
                    fontSize="30px"
                    width="60px"
                    height="60px"
                    boxShadow="md"
                    _hover={{ transform: "scale(1.1)", boxShadow: "lg" }}
                    onClick={() => {
                      generateWordDocument();
                      closeDialog();
                    }}
                  />
                  <IconButton
                    colorScheme="red"
                    aria-label="Genereer PDF"
                    icon={<FaFilePdf />}
                    fontSize="30px"
                    width="60px"
                    height="60px"
                    boxShadow="md"
                    _hover={{ transform: "scale(1.1)", boxShadow: "lg" }}
                    onClick={() => {
                      generatePDF();
                      closeDialog();
                    }}
                  />
                </Flex>
                <RadioGroup
                  onChange={setDocumentOption}
                  value={documentOption}
                  mt={4}
                >
                  <Flex flexDirection="column">
                    <Radio value="0">Alleen de samenvatting.</Radio>
                    <Radio value="1">Samenvatting en transcriptie.</Radio>
                  </Flex>
                </RadioGroup>
              </AlertDialogBody>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        <AlertDialog
          isOpen={isDeleteDialogOpen}
          leastDestructiveRef={cancelDeleteRef}
          onClose={closeDeleteDialog}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent borderRadius="15px" position="relative">
              <AlertDialogHeader
                position="relative"
                fontSize="lg"
                fontWeight="bold"
                isCentered
              >
                Verwijderen notulen
              </AlertDialogHeader>

              <AlertDialogBody>
                Weet u zeker dat u deze notulen wilt verwijderen? Deze actie kan
                niet ongedaan gemaakt worden.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelDeleteRef} onClick={closeDeleteDialog}>
                  Annuleren
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => ConversationDelete(id)}
                  ml={3}
                >
                  Verwijderen
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Card>
    </>
  );
};

export default NotulenDetailCard;
