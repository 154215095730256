import {
  FormLabel,
  form,
  Input,
  InputRightElement,
  InputGroup,
  IconButton,
  List,
  ListItem,
  ListIcon,
  Collapse,
} from "@chakra-ui/react";

import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { match } from "stylis";

function NewpasswordInput({ onPasswordChange }) {
  //Lege usestate om het wachtwoord in op te slaan en een boolean om de lijst met criteria te laten zien
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [isListVisible, setIsListVisible] = useState(false);
  // Hier wordt het wachtwoord in de state opgeslagen
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    // Als het wachtwoord niet leeg is dan wordt de lijst met criteria getoond
    setIsListVisible(newPassword.trim() !== "");
  };

  const handlePasswordConfirmChange = (e) => {
    const newPasswordConfirm = e.target.value;
    setPassword1(newPasswordConfirm);
  };
  // Wachtwoord laten zien in de UI
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  // Wachtwoord laten zien in de UI, voor het bevestigen van het wachtwoord
  const [show1, setShow1] = React.useState(false);
  const handleClick1 = () => setShow1(!show1);

  // Criteria waaraan een wachtwoord moet voldoen
  const criteria = {
    hasUpperCase: /[A-Z]/.test(password),
    hasLowerCase: /[a-z]/.test(password),
    hasEightChars: password.length >= 8,
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/g.test(password),
    hasNumber: /\d/.test(password),
    hasNoTrailingSpaces: /\s/g.test(password) === false,
  };

  const [match, setMatch] = useState(false);
  // logica voor het verifiëren van het wachtwoord
  const isPasswordValid = Object.values(criteria).every(
    (value) => value === true && password === password1
  );

  // usestate om te bepalen of het tweede veld geladen moet worden
  const [showConfirmField, setShowConfirmField] = useState(true);

  // usestate om te kijken of de wachtwoorden overeen komen

  // Als het tweede veld word geladen check dan of de wachtwoorden overeen komen
  useEffect(() => {
    if (showConfirmField === true) {
      setMatch(password === password1);
    }
  }, [password, password1, showConfirmField]);

  // Geef mee aan de parent of het wachtwoord geldig is , als het ww geldig is boolean en string returnen naar parent, geef ook mee of er een tweede veld geladen moet worden voor het bevestigen van het wachtwoord

  useEffect(() => {
    if (onPasswordChange) {
      onPasswordChange(password, isPasswordValid);
    }
  }, [password, isPasswordValid, onPasswordChange]);

  return (
    <form>
      <InputGroup>
        <Input
          borderRadius="15px"
          mb="10px"
          w="100%"
          fontSize="sm"
          type={show ? "text" : "password"}
          value={password}
          onChange={handlePasswordChange}
          placeholder="Uw wachtwoord"
          size="lg"
        />
        <InputRightElement h="full">
          <IconButton
            aria-label={show ? "Verberg wachtwoord" : "Toon wachtwoord"}
            icon={show ? <FaEyeSlash /> : <FaEye />}
            size="sm"
            mb="10px"
            variant="ghost"
            onClick={handleClick}
          />
        </InputRightElement>
      </InputGroup>
      <FormLabel ms="4px" fontSize="xs" fontWeight="normal">
        <Collapse in={isListVisible} animateOpacity>
          <List spacing={3}>
            <ListItem>
              <ListIcon
                as={criteria.hasEightChars ? CheckCircleIcon : WarningIcon}
                color={criteria.hasEightChars ? "green.500" : "red.500"}
              />
              Wachtwoord moet minstens 8 tekens bevatten
            </ListItem>
            <ListItem>
              <ListIcon
                as={criteria.hasLowerCase ? CheckCircleIcon : WarningIcon}
                color={criteria.hasLowerCase ? "green.500" : "red.500"}
              />
              Wachtwoord moet een kleine letter bevatten
            </ListItem>
            <ListItem>
              <ListIcon
                as={criteria.hasUpperCase ? CheckCircleIcon : WarningIcon}
                color={criteria.hasUpperCase ? "green.500" : "red.500"}
              />
              Wachtwoord moet een hoofdletter bevatten
            </ListItem>
            <ListItem>
              <ListIcon
                as={criteria.hasSpecialChar ? CheckCircleIcon : WarningIcon}
                color={criteria.hasSpecialChar ? "green.500" : "red.500"}
              />
              Wachtwoord moet een speciaal teken bevatten
            </ListItem>
            <ListItem>
              <ListIcon
                as={criteria.hasNumber ? CheckCircleIcon : WarningIcon}
                color={criteria.hasNumber ? "green.500" : "red.500"}
              />
              Wachtwoord moet een nummer bevatten
            </ListItem>
            <ListItem>
              <ListIcon
                as={
                  criteria.hasNoTrailingSpaces ? CheckCircleIcon : WarningIcon
                }
                color={criteria.hasNoTrailingSpaces ? "green.500" : "red.500"}
              />
              Wachtwoord mag geen voor- of achterspaties bevatten
            </ListItem>
          </List>
        </Collapse>
      </FormLabel>
      {showConfirmField && (
        <>
          <InputGroup>
            <Input
              borderRadius="15px"
              mb="10px"
              w="100%"
              fontSize="sm"
              type={show1 ? "text" : "password"}
              value={password1}
              onChange={handlePasswordConfirmChange}
              placeholder="Herhaal uw wachtwoord"
              size="lg"
            />
            <InputRightElement h="full">
              <IconButton
                aria-label={show1 ? "Verberg wachtwoord" : "Toon wachtwoord"}
                icon={show1 ? <FaEyeSlash /> : <FaEye />}
                size="sm"
                mb="10px"
                variant="ghost"
                onClick={handleClick1}
              />
            </InputRightElement>
          </InputGroup>
          <FormLabel ms="4px" fontSize="xs" fontWeight="normal">
            <Collapse in={isListVisible} animateOpacity>
              <List spacing={3}>
                <ListItem>
                  <ListIcon
                    as={match ? CheckCircleIcon : WarningIcon}
                    color={match ? "green.500" : "red.500"}
                  />
                  Wachtwoorden komen overeen
                </ListItem>
              </List>
            </Collapse>
          </FormLabel>
        </>
      )}
    </form>
  );
}

export default NewpasswordInput;
