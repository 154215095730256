import React from "react";
import {
  Button,
  Text,
  HStack,
  Flex,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { FaApple, FaGoogle } from "react-icons/fa";
import config from "../../config.json";

const googleLoginLocation = `${config.cognitoDomain}/oauth2/authorize?client_id=${config.clientId}&redirect_uri=${config.portalDomain}/sign-in-idp&identity_provider=Google&response_type=code`;
const appleLoginLocation = `${config.cognitoDomain}/oauth2/authorize?client_id=${config.clientId}&redirect_uri=https://d3fxhmic35610k.cloudfront.net/sign-in-idp&identity_provider=Google&response_type=code`;

const SocialLogin = () => {
  const AppleLogin = () => {
    console.log("Logging in with Apple");
  };

  const googleLogin = () => {
    window.open(googleLoginLocation, '_self');
  };

  const textColor = useColorModeValue("gray.400", "white");

  return (
    <>
      <Text
        fontSize="lg"
        fontWeight="bold"
        textAlign="center"
        mb="22px"
        color={textColor}
      >
        Ga verder met
      </Text>
      <HStack spacing="15px" justify="center" mb="22px">
        {/* <Flex
          justify="center"
          align="center"
          w="75px"
          h="75px"
          borderRadius="15px"
          border="1px solid lightgray"
          cursor="pointer"
        >
          <Button bg="none" onClick={AppleLogin}>
            <Icon as={FaApple} w="40px" h="40px" />
          </Button>
        </Flex> */}
        <Flex
          justify="center"
          align="center"
          w="75px"
          h="75px"
          borderRadius="15px"
          border="1px solid lightgray"
          cursor="pointer"
        >
          <Button bg="none" w="100%" h="100%" onClick={googleLogin} >
            <Icon as={FaGoogle} w="30px" h="30px" />
          </Button>
        </Flex>
      </HStack>
      <Text
        fontSize="l"
        fontWeight="bold"
        textAlign="center"
        mb="22px"
        color={textColor}
      >
        of
      </Text>
    </>
  );
};

export default SocialLogin;
