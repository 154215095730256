// SignIn.js

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  List,
  ListIcon,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  Checkbox,
  InputRightElement,
  InputGroup,
  useToast,
  Link,
  IconButton, Tooltip,
} from "@chakra-ui/react";
import NewpasswordInput from "components/PasswordInput/passwordInput";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { ChallengeNameType } from "@aws-sdk/client-cognito-identity-provider";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// Assets
import { FaEye, FaArrowLeft, FaEyeSlash } from "react-icons/fa";
import cover from "assets/img/cover-auth.png";
import React, { useRef, useState, useEffect } from "react";
import {
  signIn,
  RespondToMfaChallenge,
  RespondToNewPasswordChallenge,
  VerifyMfaCode,
  initiateForgotPassword,
  confirmForgotPassword,
} from "services/authService";
import QRCode from "qrcode.react";
import { set } from "date-fns";
import SocialLogin from "components/Sociallogin/SocialLogin";
function SignIn() {
  const basicStepTab = useRef();
  const mfaStepTab = useRef();
  const newPasswordStepTab = useRef();
  const forgotPasswordTab = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signInError, setSignInError] = useState("");
  const [cognitoSession, setCognitoSession] = useState("");
  const [mfaCode, setMfaCode] = useState(new Array(6).fill(""));
  const [mfaSetupQrUrl, setMfaSetupQrUrl] = useState("");
  const [mfaSecretcode, setMfaSecretcode] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordHasUpperCase, setNewPasswordHasUpperCase] = useState(false);
  const [newPasswordHasEightChars, setNewPasswordHasEightChars] = useState(
    false
  );
  const [newPasswordHasLowerCase, setNewPasswordHasLowerCase] = useState(false);
  const [newPasswordHasSpecialChar, setNewPasswordHasSpecialChar] = useState(
    false
  );
  const [newPasswordHasNumber, setNewPasswordHasNumber] = useState(false);
  const [
    newPasswordHasNoTrailingSpaces,
    setNewPasswordHasNoTrailingSpaces,
  ] = useState(false);

  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [mfaSignInError, setMfaSignInError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [mfaSetupError, setMfaSetupError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handlePasswordChange = (password, isValid) => {
    setForgotPasswordNewPassword(password);
    setCanProceed(isValid);
  };

  const toast = useToast();

  const emailInputRef = useRef(null);
  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  const inputRefsSignIn = useRef([...Array(6)].map(() => useRef(null)));
  const inputRefsSetup = useRef([...Array(6)].map(() => useRef(null)));

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const handleNavigation = () => {
    window.location.href = "/auth/sign-up"; // doorgaan naar de inlogpagina
  };

  useEffect(() => {
    if (mfaStepTab.current && cognitoSession) {
      if (inputRefsSignIn.current[0] && inputRefsSignIn.current[0].current) {
        inputRefsSignIn.current[0].current.focus(); // Set focus to the first input
      }
    }
  }, [cognitoSession, mfaStepTab]);

  const handleChangeSignIn = (value, index) => {
    if (/^\d$/.test(value) || value === "") {
      const newMfaCode = [...mfaCode];
      newMfaCode[index] = value;
      setMfaCode(newMfaCode);

      if (value && index < inputRefsSignIn.current.length - 1) {
        inputRefsSignIn.current[index + 1].current.focus();
      }
    }
  };

  const handleChangeSetup = (value, index) => {
    if (/^\d$/.test(value) || value === "") {
      const newMfaCode = [...mfaCode];
      newMfaCode[index] = value;
      setMfaCode(newMfaCode);

      if (value && index < inputRefsSetup.current.length - 1) {
        inputRefsSetup.current[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownSignIn = (e, index) => {
    if (e.key === "Backspace" && mfaCode[index] === "" && index > 0) {
      inputRefsSignIn.current[index - 1].current.focus();
    }
  };

  const handleKeyDownSetup = (e, index) => {
    if (e.key === "Backspace" && mfaCode[index] === "" && index > 0) {
      inputRefsSetup.current[index - 1].current.focus();
    }
  };

  const handlePasteSignIn = (e) => {
    const pastedData = e.clipboardData.getData("Text").trim();

    if (/^\d{6}$/.test(pastedData)) {
      const newMfaCode = pastedData.split("");
      setMfaCode(newMfaCode);

      // Move focus to the last input
      inputRefsSignIn.current[5].current.focus();
    }
  };

  const handlePasteSetup = (e) => {
    const pastedData = e.clipboardData.getData("Text").trim();

    if (/^\d{6}$/.test(pastedData)) {
      const newMfaCode = pastedData.split("");
      setMfaCode(newMfaCode);

      // Move focus to the last input
      inputRefsSetup.current[5].current.focus();
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const response = await signIn(email, password);
      const { ChallengeName, Session, AuthenticationResult } = response;

      if (AuthenticationResult) {
        // Direct doorsturen als er geen uitdaging is
        console.log("Succesvolle login zonder MFA");
        window.location.href = "/";
        return;
      }

      if (ChallengeName === ChallengeNameType.SOFTWARE_TOKEN_MFA) {
        setCognitoSession(Session);
        mfaStepTab.current.click();
      }

      if (ChallengeName === ChallengeNameType.NEW_PASSWORD_REQUIRED) {
        setCognitoSession(Session);
        newPasswordStepTab.current.click();
      }
    } catch (error) {
      setSignInError(error.message);
    }
  };
  
  const handleSignInMfa = async (e) => {
    e.preventDefault();
    const mfaCodeString = mfaCode.join("");

    try {
      const session = await RespondToMfaChallenge(
        email,
        mfaCodeString,
        cognitoSession,
        rememberMe
      );
      if (session && typeof session.AccessToken !== "undefined") {
        window.location.href = "/admin/dashboard/notulen";
      }
    } catch (error) {
      setMfaSignInError(error.message);
    }
  };

  const [mfaChoice, setMfaChoice] = useState(null);

  const handleNewPassword = async (e) => {
    e.preventDefault();

    // Reset error messages
    setNewPasswordError("");

    // Check if passwords match
    if (newPassword !== confirmNewPassword) {
      setNewPasswordError("Wachtwoorden komen niet overeen.");
      return;
    }

    try {
      // Send the new password to Cognito
      const response = await RespondToNewPasswordChallenge(
        email,
        newPassword,
        cognitoSession
      );

      if (response && response.AuthenticationResult) {
        // Save tokens if needed
        // For example: localStorage.setItem('accessToken', response.AuthenticationResult.AccessToken);
        // Redirect the user to the secured page
        window.location.href = "/";
      } else {
        // If there is no AuthenticationResult, show an error message
        setNewPasswordError(
          "Authenticatie mislukt na het instellen van het nieuwe wachtwoord."
        );
      }
    } catch (error) {
      setNewPasswordError(error.message || "Er is een fout opgetreden.");
    }
  };

  // --- Forgot Password States ---
  // Deze usestate laat zien of de wachtwoorden overeen komen
  const [canProceed, setCanProceed] = useState(false);

  const [forgotPasswordStep, setForgotPasswordStep] = useState(1);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordCode, setForgotPasswordCode] = useState(
    new Array(6).fill("")
  );
  const [forgotPasswordNewPassword, setForgotPasswordNewPassword] = useState(
    ""
  );
  const [forgotPasswordError, setForgotPasswordError] = useState("");
  const inputRefsForgotPassword = useRef([...Array(6)].map(() => useRef(null)));

  // Handlers for Forgot Password
  const handleInitiateForgotPassword = async (e) => {
    e.preventDefault();
    setForgotPasswordError("");
    // setForgotPasswordSuccess("");
    // test push
    try {
      await initiateForgotPassword(forgotPasswordEmail);
      // setForgotPasswordSuccess("Verificatiecode verzonden naar uw e-mail.");
      toast({
        title: "Verificatiecode verzonden naar uw e-mail.",
        // description: "Het notulen Word-document is succesvol gegenereerd.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setForgotPasswordStep(2);
      // Focus on the first code input
      if (inputRefsForgotPassword.current[0].current) {
        inputRefsForgotPassword.current[0].current.focus();
      }
    } catch (error) {
      console.log(error);
      setForgotPasswordError(
        error.message || "Fout bij het initiëren van wachtwoordherstel."
      );
    }
  };

  const handleChangeForgotPasswordCode = (value, index) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...forgotPasswordCode];
      newCode[index] = value;
      setForgotPasswordCode(newCode);

      if (value && index < inputRefsForgotPassword.current.length - 1) {
        inputRefsForgotPassword.current[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownForgotPassword = (e, index) => {
    if (
      e.key === "Backspace" &&
      forgotPasswordCode[index] === "" &&
      index > 0
    ) {
      inputRefsForgotPassword.current[index - 1].current.focus();
    }
  };

  const handlePasteForgotPassword = (e) => {
    const pastedData = e.clipboardData.getData("Text").trim();

    if (/^\d{6}$/.test(pastedData)) {
      const newCode = pastedData.split("");
      setForgotPasswordCode(newCode);

      // Move focus to the last input
      inputRefsForgotPassword.current[5].current.focus();
    }
  };

  const handleConfirmForgotPassword = async (e) => {
    e.preventDefault();
    setForgotPasswordError("");
    // setForgotPasswordSuccess("");

    const verificationCode = forgotPasswordCode.join("");

    // Check if passwords match
    if (canProceed === false) {
      setForgotPasswordError("Wachtwoorden komen niet overeen.");
      return;
    }

    try {
      await confirmForgotPassword(
        forgotPasswordEmail,
        verificationCode,
        forgotPasswordNewPassword
      );
      // setForgotPasswordSuccess("Wachtwoord succesvol gewijzigd. U kunt nu inloggen.");
      toast({
        title: "Wachtwoord succesvol gewijzigd",
        description: "U kunt nu inloggen.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Optionally, redirect to sign-in
      // Reset the forgot password state
      setForgotPasswordStep(1);
      setForgotPasswordEmail("");
      setForgotPasswordCode(new Array(6).fill(""));
      setForgotPasswordNewPassword("");
      // Switch back to the sign-in tab
      basicStepTab.current.click();
    } catch (error) {
      setForgotPasswordError(
        error.message || "Fout bij het bevestigen van nieuw wachtwoord."
      );
    }
  };
  
  
  
  
 
  const verifyNewPassword = (modifiedPassword) => {
    setNewPasswordHasUpperCase(/[A-Z]/.test(modifiedPassword));
    setNewPasswordHasLowerCase(/[a-z]/.test(modifiedPassword));
    setNewPasswordHasSpecialChar(
      /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(modifiedPassword)
    );
    setNewPasswordHasEightChars(modifiedPassword.length >= 8);
    setNewPasswordHasNumber(/\d/.test(modifiedPassword));
    setNewPasswordHasNoTrailingSpaces(/\s/g.test(modifiedPassword) === false);
  };

  const titleColor = useColorModeValue("blue.300", "blue.200");
  const textColor = useColorModeValue("gray.400", "white");

  // Back button
  const tooltipBg = useColorModeValue("gray.300", "gray.700");
  const buttonBg = useColorModeValue("gray.200", "gray.600");
  const buttonHoverBg = useColorModeValue("gray.300", "gray.500");
  const iconColor = useColorModeValue("gray.800", "white");
  return (
    <Flex position="relative">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Welkom bij het LawDisk™ portaal.
            </Heading>
            <Text ms="4px" color={textColor} fontWeight="bold" fontSize="14px">
              Vul uw gegevens in.
            </Text>
            <Flex w="100%">
              <Tabs>
                <TabList
                  display="none" // Hide the tab buttons
                  align="center"
                  alignSelf="center"
                  justifySelf="center"
                >
                  <Tab ref={basicStepTab}></Tab>
                  <Tab ref={mfaStepTab}></Tab>
                  <Tab ref={newPasswordStepTab}></Tab>
                  <Tab ref={forgotPasswordTab}></Tab>{" "}
                  {/* Added Forgot Password Tab */}
                </TabList>
                <TabPanels
                  variant="unstyled"
                  mt="24px"
                  display="flex"
                  flexDirection="column"
                >
                  {/* Eerste TabPanel: Inloggen */}
                  <TabPanel>
                    <form>
                      <SocialLogin />
                      <FormControl isInvalid={signInError}>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Email
                        </FormLabel>
                        <Input
                          ref={emailInputRef} // Add ref for auto focus
                          borderRadius="15px"
                          mb="10px"
                          w="100%"
                          fontSize="sm"
                          type="text"
                          value={email}
                          onChange={(e) =>
                            setEmail(e.target.value.toLowerCase())
                          } // Convert input to lowercase
                          placeholder="Uw emailadres"
                          size="lg"
                        />
                        <FormErrorMessage mb="10px" fontSize="md">
                          {signInError}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Wachtwoord
                        </FormLabel>
                        <InputGroup>
                          <Input
                            borderRadius="15px"
                            mb="10px"
                            fontSize="sm"
                            type={show ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Uw wachtwoord"
                            size="lg"
                          />
                          <InputRightElement h="full">
                            <IconButton
                              aria-label={
                                show ? "Verberg wachtwoord" : "Toon wachtwoord"
                              }
                              icon={show ? <FaEyeSlash /> : <FaEye />}
                              size="sm"
                              mb="10px"
                              variant="ghost"
                              onClick={handleClick}
                            />
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage mb="10px"></FormErrorMessage>
                      </FormControl>
                      {/* Forgot Password Link */}
                      <Text
                        fontSize="sm"
                        color={titleColor}
                        fontWeight="bold"
                        cursor="pointer"
                        onClick={() => {
                          forgotPasswordTab.current.click();
                          setForgotPasswordStep(1); // Reset forgot password step
                          setForgotPasswordEmail(email); // Optionally pre-fill email
                          setForgotPasswordCode(new Array(6).fill(""));
                          setForgotPasswordNewPassword("");
                          setForgotPasswordError("");
                          // setForgotPasswordSuccess("");
                        }}
                        textAlign="right"
                      >
                        Wachtwoord vergeten?
                      </Text>
                      <FormControl>
                        <Button
                          fontSize="sm"
                          type="submit"
                          bg="blue.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          onClick={handleSignIn}
                          _hover={{
                            bg: "blue.200",
                          }}
                          _active={{
                            bg: "blue.400",
                          }}
                        >
                          LOG IN
                        </Button>
                        <Text
                          color={textColor}
                          fontWeight="medium"
                          align="center"
                        >
                          Heeft u nog geen account?
                          <Link
                            onClick={handleNavigation}
                            color={titleColor}
                            as="span"
                            ms="5px"
                            fontWeight="bold"
                          >
                            Account aanmaken
                          </Link>
                        </Text>
                        <FormErrorMessage>{signInError}</FormErrorMessage>
                      </FormControl>
                    </form>
                  </TabPanel>

                  {/* Tweede TabPanel: 2FA Code */}
                  <TabPanel>
                    <form>
                      <FormControl isInvalid={mfaSignInError}>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          2FA Code
                        </FormLabel>

                        <Flex justify="center" mb="24px">
                          {mfaCode.map((_, index) => (
                            <Input
                              key={index}
                              ref={inputRefsSignIn.current[index]}
                              value={mfaCode[index]}
                              onChange={(e) =>
                                handleChangeSignIn(e.target.value, index)
                              }
                              onKeyDown={(e) => handleKeyDownSignIn(e, index)}
                              onPaste={(e) => handlePasteSignIn(e)}
                              maxLength={1}
                              inputMode="numeric"
                              pattern="\d*"
                              textAlign="center"
                              fontSize="2xl"
                              width="50px"
                              height="50px"
                              borderColor="gray.300"
                              _focus={{ borderColor: "blue.300" }}
                              _hover={{ borderColor: "gray.500" }}
                              mx="5px"
                            />
                          ))}
                        </Flex>

                        <FormControl
                          display="flex"
                          alignItems="center"
                          mb="36px"
                        >
                          <Checkbox
                            isChecked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                            colorScheme="blue"
                          >
                            Onthoud mij voor 14 dagen op dit apparaat
                          </Checkbox>
                        </FormControl>

                        <FormErrorMessage>{mfaSignInError}</FormErrorMessage>

                        <Button
                          fontSize="sm"
                          type="submit"
                          bg="blue.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          onClick={handleSignInMfa}
                          _hover={{ bg: "blue.200" }}
                          _active={{ bg: "blue.400" }}
                        >
                          LOG IN
                        </Button>
                      </FormControl>
                    </form>
                  </TabPanel>

                  {/* Derde TabPanel: Nieuwe Wachtwoord */}
                  <TabPanel>
                    <form>
                      <FormControl isInvalid={newPasswordError}>
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Nieuw wachtwoord
                          </FormLabel>
                          <InputGroup>
                            <Input
                              borderRadius="15px"
                              mb="10px"
                              fontSize="sm"
                              type={show ? "text" : "password"}
                              value={newPassword}
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                                verifyNewPassword(e.target.value);
                              }}
                              placeholder="Uw nieuwe wachtwoord"
                              size="lg"
                            />
                            <InputRightElement h="full">
                              <IconButton
                                aria-label={
                                  show
                                    ? "Verberg wachtwoord"
                                    : "Toon wachtwoord"
                                }
                                icon={show ? <FaEyeSlash /> : <FaEye />}
                                size="sm"
                                mb="10px"
                                variant="ghost"
                                onClick={handleClick}
                              />
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage mb="10px">
                            {newPasswordError}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Bevestig wachtwoord
                          </FormLabel>
                          <InputGroup>
                            <Input
                              borderRadius="15px"
                              mb="10px"
                              fontSize="sm"
                              type={show ? "text" : "password"}
                              value={confirmNewPassword}
                              onChange={(e) =>
                                setConfirmNewPassword(e.target.value)
                              }
                              placeholder="Bevestig uw nieuwe wachtwoord"
                              size="lg"
                            />
                            <InputRightElement h="full">
                              <IconButton
                                aria-label={
                                  show
                                    ? "Verberg wachtwoord"
                                    : "Toon wachtwoord"
                                }
                                icon={show ? <FaEyeSlash /> : <FaEye />}
                                size="sm"
                                mb="10px"
                                variant="ghost"
                                onClick={handleClick}
                              />
                            </InputRightElement>
                          </InputGroup>
                          <FormLabel ms="4px" fontSize="xs" fontWeight="normal">
                            <List spacing={3}>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasEightChars
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasEightChars
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord moet minstens 8 tekens bevatten
                              </ListItem>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasLowerCase
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasLowerCase
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord moet een kleine letter bevatten
                              </ListItem>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasUpperCase
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasUpperCase
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord moet een hoofdletter bevatten
                              </ListItem>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasSpecialChar
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasSpecialChar
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord moet een speciaal teken bevatten
                              </ListItem>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasNumber
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasNumber
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord moet een nummer bevatten
                              </ListItem>
                              <ListItem>
                                <ListIcon
                                  as={
                                    newPasswordHasNoTrailingSpaces
                                      ? CheckCircleIcon
                                      : WarningIcon
                                  }
                                  color={
                                    newPasswordHasNoTrailingSpaces
                                      ? "green.500"
                                      : "red.500"
                                  }
                                />
                                Wachtwoord mag geen voor- of achterspaties
                                bevatten
                              </ListItem>
                            </List>
                          </FormLabel>
                          <FormErrorMessage mb="10px"></FormErrorMessage>
                        </FormControl>
                        <Button
                          fontSize="sm"
                          type="submit"
                          bg="blue.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          onClick={handleNewPassword}
                          _hover={{
                            bg: "blue.200",
                          }}
                          _active={{
                            bg: "blue.400",
                          }}
                        >
                          LOG IN
                        </Button>
                      </FormControl>
                    </form>
                  </TabPanel>

                  {/* Vierde TabPanel: Forgot Password */}
                  <TabPanel>
                    <Flex
                      position="absolute"
                      top="100px"
                      left="40px"
                      zIndex="10"
                    >
                      <Tooltip
                        hasArrow
                        label="Ga terug"
                        bg={tooltipBg}
                        color={useColorModeValue("black", "white")}
                      >
                        <IconButton
                          icon={<FaArrowLeft />}
                          aria-label="Terug"
                          color={iconColor}
                          borderRadius="50%"
                          boxSize="40px"
                          bg={buttonBg}
                          _hover={{ bg: buttonHoverBg }}
                          onClick={() =>
                            (window.location.href = "/auth/sign-in")
                          }
                        />
                      </Tooltip>
                    </Flex>

                    {forgotPasswordStep === 1 && (
                      <>
                        <form>
                          <Text
                            mb="36px"
                            color={textColor}
                            fontWeight="bold"
                            fontSize="14px"
                          >
                            Voer je e-mailadres om een verificatiecode te
                            ontvangen
                          </Text>
                          <FormControl isInvalid={forgotPasswordError}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                              w="100"
                            >
                              Email
                            </FormLabel>
                            <Input
                              borderRadius="15px"
                              mb="10px"
                              w="100%"
                              fontSize="sm"
                              type="text"
                              value={forgotPasswordEmail}
                              onChange={(e) =>
                                setForgotPasswordEmail(
                                  e.target.value.toLowerCase()
                                )
                              }
                              placeholder="Uw emailadres"
                              size="lg"
                            />
                            <FormErrorMessage mb="10px">
                              {forgotPasswordError}
                            </FormErrorMessage>
                          </FormControl>
                          <Button
                            fontSize="sm"
                            type="submit"
                            bg="blue.300"
                            w="100%"
                            h="45"
                            mb="20px"
                            color="white"
                            mt="20px"
                            onClick={handleInitiateForgotPassword}
                            _hover={{
                              bg: "blue.200",
                            }}
                            _active={{
                              bg: "blue.400",
                            }}
                          >
                            VERZEND VERIFICATIECODE
                          </Button>
                        </form>
                        <Flex>
                          <Text color={textColor} fontWeight="medium">
                            Terug naar
                          </Text>
                          <Link
                            onClick={() => basicStepTab.current?.click()}
                            color={titleColor}
                            as="span"
                            ms="5px"
                            fontWeight="bold"
                          >
                            Inloggen
                          </Link>
                        </Flex>
                      </>
                    )}

                    {forgotPasswordStep === 2 && (
                      <form>
                        <FormControl isInvalid={forgotPasswordError}>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Verificatiecode
                          </FormLabel>
                          <Flex mb="24px" maxW="100%">
                            {forgotPasswordCode.map((_, index) => (
                              <Input
                                padding="0"
                                lineHeight="normal"
                                key={index}
                                ref={inputRefsForgotPassword.current[index]}
                                value={forgotPasswordCode[index]}
                                onChange={(e) =>
                                  handleChangeForgotPasswordCode(
                                    e.target.value,
                                    index
                                  )
                                }
                                onKeyDown={(e) =>
                                  handleKeyDownForgotPassword(e, index)
                                }
                                onPaste={(e) => handlePasteForgotPassword(e)}
                                maxLength={1}
                                inputMode="numeric"
                                pattern="\d*"
                                textAlign="center"
                                // fontSize="xl"
                                width="37px"
                                height="50px"
                                borderColor="gray.300"
                                _focus={{ borderColor: "blue.300" }}
                                _hover={{ borderColor: "gray.500" }}
                                mx="5px"
                              />
                            ))}
                          </Flex>

                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            <NewpasswordInput
                              onPasswordChange={handlePasswordChange}
                            />
                            <FormErrorMessage mb="10px">
                              {forgotPasswordError}
                            </FormErrorMessage>
                          </FormLabel>
                        </FormControl>
                        <Button
                          fontSize="sm"
                          type="submit"
                          bg="blue.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          isDisabled={!canProceed}
                          onClick={handleConfirmForgotPassword}
                          _hover={{
                            bg: "blue.200",
                          }}
                          _active={{
                            bg: "blue.400",
                          }}
                        >
                          Bevestig Wachtwoord
                        </Button>
                      </form>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={cover}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
