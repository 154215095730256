import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Header from "views/Pages/Profile/Overview/components/Header";
import { getUserName } from "extensions/userInfoExtension";
import { getConversation } from "api/lawdiskService";
import { format } from 'date-fns';
import NotulenDetailCard from "./Components/NotulenDetailCard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function NotulenDetail() {
  const [data, setData] = useState(null);
  const [conversationId, setConversationId] = useState(null);

  // Use useParams if the ID is part of the route params
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // If not using useParams, get the id from the URL
        const idFromPath = id || window.location.pathname.split("detail/")[1].trim();
        setConversationId(idFromPath);
        // Fetch conversation data
        const conversation = await getConversation(idFromPath);
        setData(conversation);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <Box p='4'>
        {data !== undefined && <NotulenDetailCard
          id={data?.id}
          summary={data?.summary}
          text={data?.text}
          title={data?.title}
          date={data?.createdAt}
          tags={data?.tags}
          userId={data?.userId}
        ></NotulenDetailCard>}
      </Box>
    </Flex >
  );
}

export default NotulenDetail;
