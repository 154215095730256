import { Document, Paragraph, TextRun, AlignmentType } from "docx";

export class LawDiskDocumentCreator {
  create(data) {
    const { summary, transcript, date, tags, isSummaryOnly } = data;

    const document = new Document({
      styles: {
        default: {
          document: {
            run: {
              font: "Corbel", // Set default font
            },
          },
        },
      },
      sections: [
        {
          children: [
            // Title: "LawDisk Notulen"
            new Paragraph({
              children: [
                new TextRun({
                  text: "LawDisk Notulen",
                  bold: true,
                  size: 32, // 24 points in Word
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: { after: 100 },
            }),
            // Subheading: "Van de vergadering op [date]"
            new Paragraph({
              children: [
                new TextRun({
                  text: `Van de vergadering op ${date}`,
                  size: 20, // 12 points in Word
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: { after: 200 },
            }),
            // Tags section
            this.createHeading("Tags"),
            new Paragraph({
              children: [
                new TextRun({
                  text: tags.join(", "),
                  size: 20, // Font size for tags
                }),
              ],
              spacing: { after: 200 },
            }),
            // Samenvatting section
            this.createHeading("Samenvatting"),
            ...this.parseMarkdown(summary),
            // Voeg transcriptie alleen toe als isSummaryOnly false is
            ...(isSummaryOnly
              ? []
              : [
                  this.createHeading("Transcriptie"),
                  ...this.parseMarkdown(transcript),
                ]),
          ],
        },
      ],
    });

    return document;
  }

  // Helper function to create a heading
  createHeading(text) {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 28, // 18 points in Word
        }),
      ],
      spacing: { before: 200, after: 100 },
    });
  }

  // Function to parse Markdown-like text
  parseMarkdown(markdown) {
    const paragraphs = [];
    const lines = markdown.split("\n");

    lines.forEach((line) => {
      if (line.startsWith("#### ")) {
        // H2 Heading
        paragraphs.push(
          new Paragraph({
            children: [
              new TextRun({
                text: line.replace("#### ", ""),
                bold: true,
                size: 24, // 16 points in Word
              }),
            ],
            spacing: { before: 100, after: 100 },
          })
        );
     
      } else {
        // Normal text
        paragraphs.push(
          new Paragraph({
            children: [
              new TextRun({
                text: line,
                size: 20, // 12 points in Word
              }),
            ],
            spacing: { after: 50 },
          })
        );
      }
    });

    return paragraphs;
  }
}
